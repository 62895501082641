//
//
//
//
//
//
//
//

import BarMenuEditor from '../components/BarMenuEditor.vue';

export default {
  //=====================================================
  name: 'App',
  //=====================================================
  components: { BarMenuEditor },
  //=====================================================
}
